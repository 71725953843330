<template>
  <div class="uk-grid" data-uk-grid>
    <div class="uk-width-1-1">
      {{data.body}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  }
}
</script>
